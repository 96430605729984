import { useCallback, useEffect, useState } from "react";
import { default as ReactCookieConsent, getCookieConsentValue } from "react-cookie-consent";
import styles from './styles.module.scss'
import cn from 'classnames'

const DEBUG_MODE = false;

/**
 *
 * @param className
 * @param cookieName
 * @param location
 * @param overlay
 * @param messageText
 * @param acceptButtonText
 * @param declineButtonText
 * @param enableDeclineButton
 * @param flipButtons
 *
 * @param children - Scripts allowed once cookie consent is given
 *
 * @returns {JSX.Element}
 * @constructor
 */
export default function CookieConsent({
  className = 'p-4 m-2',
  cookieName = '_sf_cookie_consent',
  location = 'bottom',
  overlay = false,
  messageText = 'We use cookies to provide the best possible experience for our users. They help us provide essential functionality and improve site performance, and allow us to offer a more personalised experience when using the site. For more information: <a href="https://www.staffline.co.uk/cookie-policy">Cookie policy</a>',
  acceptButtonText = "I Understand",
  declineButtonText = "Decline",
  enableDeclineButton = true,
  flipButtons = true,
  children
}) {
  const [cookiesAllowed, setCookiesAllowed] = useState(false);

  const checkCookieExists = useCallback(() => {
    setCookiesAllowed(!!getCookieConsentValue(cookieName));
  }, [])

  useEffect(() => {
    checkCookieExists()
  }, [])

  return (
    <>
      {cookiesAllowed && <>{children}</>}
      <ReactCookieConsent
        setDeclineCookie={false}
        disableStyles={true}
        debug={DEBUG_MODE}

        // Props
        cookieName={cookieName}
        location={location}
        overlay={overlay}
        buttonText={acceptButtonText}
        enableDeclineButton={enableDeclineButton}
        declineButtonText={declineButtonText}
        flipButtons={flipButtons}

        // Classes
        containerClasses={cn(className, styles.cookieConsent)}
        buttonWrapperClasses={styles.cookieConsent__buttonWrapper}
        buttonClasses={styles.cookieConsent__button}
        declineButtonClasses={styles.cookieConsent__buttonDecline}
        overlayClasses={styles.cookieConsent__overlay}

        // Actions
        onAccept={checkCookieExists}
        onDecline={checkCookieExists}
      >
        <span dangerouslySetInnerHTML={{__html: messageText}} />
      </ReactCookieConsent>
    </>
  );
}
